.account {
    .navButton {
        margin-bottom: 0.5em;
    }

    .cardContainer {
        height: calc(100vh - 265px);
    }

    .fullHeight {
        height: 100%;
    }

    .tableContainer {
        margin-top: 1em;
        height: calc(100vh - 495px);
    }

    .inviteButtonContainer {
        display: flex;
        justify-content: flex-end;
    }

    .organizationImage {
        width: 160px;
        height: 160px;
        background-color: white;
        border-radius: 1em;
    }

    td {
        vertical-align: middle !important;
    }
    
    .table {
        --bs-table-bg: #ffffff !important;
    }

    .clickable {
        cursor: pointer;
    }
}