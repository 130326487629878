#projects-page td {
    vertical-align: middle !important;
}

#projects-page .table {
    --bs-table-bg: #ffffff !important;
}

.map-container {
    min-height: 100%;
}

.map-container-for-init-proj {
    height: 400px;
}

.map-container-for-preview-locations {
    height: 400px;
}

.coordinate-bar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    margin: 12px;
    border-radius: 4px;
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    text-align: center;
}

.loading-spinner {
    width: 3rem !important;
    height: 3rem !important;
}

.offcanvas-start {
    width: 255px !important;
}

.offcanvas-header {
    padding-bottom: 0.4rem !important;
}

.toast {
    opacity: 1 !important;
}

.anchor-type-card i {
    font-size: 40px;
    margin-bottom: 8px;
}

.anchor-type-card .anchor-type-desc {
    font-size: 10px;
    font-weight: 300;
}

.anchor-type-card .soon {
    position: absolute;
    top: 10px;
    right: 30px;
}

.text-label-desc {
    font-size: 10px;
    font-weight: 300;
}

.mapboxgl-ctrl-geocoder {
    width: 100%;
    max-width: none;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, .1);
}