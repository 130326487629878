.uploadManager {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1.5em;
}

.simpleBarContainer {
    max-height: calc(100vh - 220px);
    &.simpleBarContainer-mini {
        max-height: calc(100vh - 120px);
    }
}

.uploadButtonSpacer {
    height: 160px
}
