@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/buttons";
@import "../../../assets/scss/variables";


.liteButton {
    @extend .btn;
    background-color: rgba($color: #000000, $alpha: 0);
    color: $secondary;
    padding: 12px;

    &:hover,
    &:active,
    &.selected {
        background-color: rgba($color: $primary, $alpha: 0.08);
        color: $primary;
        border-color: $primary !important;
    }
    &.error {
        background-color: rgba($color: $danger, $alpha: 0.08);
        color: $danger;
        border-color: $danger !important;
    }
}