.lockableIcon {
    display: flex;
    justify-content: flex-end;
}

.lockableTitle {
    display: flex;
    align-content: center;
}

.lockTitle {
    margin: auto 0;

}

.lockIcon {
    cursor: pointer;
}