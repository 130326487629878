.toolsBar {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    padding: 8px 5px;
    border-radius: 8px;
    margin: 5px 5px;
    z-index: 10;

    .toolItem {
        margin: 0 4px;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        background-color: #f4f4f4;
        display: flex;
        justify-content: center;
        align-items: center;

        &--selected {
            background-color: #c2c2c2;
        }
    }

    .toolItem:hover {
        @extend .toolItem;
        cursor: pointer;
        background-color: #c2c2c2;
    }

    .toolIcon {
        font-size: 16px;
    }
}