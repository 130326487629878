.pointCloudToggleContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 5px;
    right: 45px;
    z-index: 10;
    height: 40px;
}

.pointCloudTPopoverContainer {
    min-width: 190px;
}
