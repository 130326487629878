.uploadFieldContainer {
    height: 350px;
}

.uploadField {
    .icon {
        font-size: 54px;
    }

    &.disabled {
        background-color: darkgrey;
    }
}