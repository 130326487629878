#project-editor-header {
    background-color: #181b25;
    /* margin-bottom: 15px; */
}

#project-editor-header .navbar-header {
    height: 55px !important;
}

#project-editor-header .navbar-brand-box {
    width: 128px !important;
}

#project-editor-header h5 {
    font-size: 16px;
}

#project-editor {
    height: 100vh;
    overflow-y: hidden !important;
}

#project-editor .container-fluid,
#project-editor .row {
    padding: 0;
}

#project-editor .card {
    margin-bottom: 0 !important;
    height: 100%;
}

#project-editor .card-body {
    max-height: 100%;
}

#project-editor .card-container {
    height: calc(100vh - 55px);
    /* padding-top: 16px;
    padding-bottom: 16px; */
}

#project-editor-header .btn .large-icon {
    font-size: 20px;
    color: #fff;
    margin-top: 4px;
}

#project-editor-header .vertical-line {
    border-left: 1px solid rgb(185, 185, 185);
    height: 32px;
    margin-left: 12px;
    margin-right: 24px;
}

#project-editor-header .d-flex {
    align-items: center;
}

#project-editor .project-name {
    margin-bottom: 0 !important;
    color: #fff;
    margin-right: 12px;
}

#upload-anchor-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#upload-anchor-image i {
    cursor: pointer;
}

.preview-menu-container {
    width: 220px;
    text-align: center;
    padding: 0em 1em;
}

.preview-menu-item {
    margin-bottom: 0.5rem;
    font-size: 10px;
    color: #a0a0a0;
}

/* #project-editor-header #anchor-selector button:focus {
    outline: none !important;
    border: none;
} */
