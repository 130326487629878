.arContentOption {
    .lockableIcon {
        display: flex;
        justify-content: flex-end;
    }

    .lockableTitle {
        display: flex;
        align-content: center;
    }

    .lockTitle {
        margin: auto 0;

    }

    .lockIcon {
        cursor: pointer;
    }

    .labelButton {
        border-radius: 0.6rem;
        width: 1.5rem;
        border: none;
    }

    .btn-group {
        margin-bottom: .5rem;
    }
}