.arContentRow {
    .arContentTitle {
        display: inline-block;
        justify-content: flex-start;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 120px;
        overflow: hidden;
        @media only screen and (max-width: 1650px) {
            max-width: 80px;
        }
        @media only screen and (max-width: 1400px) {
            max-width: 60px;
        }
    }

    div {
        align-items: center;
    }

    button {
        margin-bottom: 0.4em;
    }
}
