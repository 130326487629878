@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../assets/scss/variables";

.graffityCol,
.graffityRow,
.Box {
    &.marginBottomS {
        margin-bottom: 0.2rem;
    }
    &.marginBottomM {
        margin-bottom: 0.5rem;
    }
    &.marginBottomL {
        margin-bottom: 1rem;
    }

    &.paddingS {
        padding: 0.2rem;
    }
    &.paddingM {
        padding: 0.5rem;
    }
    &.paddingL {
        padding: 1rem;
    }
    &.paddingXL {
        padding: 1.5em;
    }

    &.displayFlex {
        display: flex;
    }

    &.justifyCenter {
        justify-content: center;
    }
    &.justifyStart {
        justify-content: flex-start;
    }
    &.justifyEnd {
        justify-content: flex-end;
    }
    &.justifySpaceBetween {
        justify-content: space-between;
    }
    &.justifySpaceAround {
        justify-content: space-around;
    }

    &.alignItemCenter {
        align-items: center;
    }

    &.border-gray-400 {
        border-color: $gray-400 !important;
    }
}